import React, { HTMLAttributes, useRef } from 'react';
import { useInteractionModality } from '@react-aria/interactions';
import { useTooltip, useTooltipTrigger } from '@react-aria/tooltip';
import {
  TooltipTriggerState,
  useTooltipTriggerState,
} from '@react-stately/tooltip';

const InternalTooltip: React.FC<
  { state: TooltipTriggerState; text: string } & HTMLAttributes<HTMLElement>
> = ({ state, text, ...props }) => {
  const { tooltipProps } = useTooltip(props, state);

  return (
    <div {...tooltipProps} className="w-auto text-center absolute">
      <div className="">
        <div className="bg-white text-black text-xs border border-card rounded py-1 px-2">
          {text}
        </div>
      </div>
    </div>
  );
};

const ElapsedTimeTooltip: React.FC<{ text: string }> = ({ children, text }) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useInteractionModality();
  const tooltipState = useTooltipTriggerState({ delay: 0 });
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    {},
    tooltipState,
    targetRef
  );

  return (
    <div className="relative">
      {tooltipState.isOpen && (
        <InternalTooltip state={tooltipState} {...tooltipProps} text={text} />
      )}
      <div ref={targetRef} {...triggerProps}>
        {children}
      </div>
    </div>
  );
};

export default ElapsedTimeTooltip;
