import React, { useRef } from 'react';
import ModalBase from 'react-modal';
import { XIcon } from '@heroicons/react/outline';
import { useButton } from '@react-aria/button';

type ModalProps = {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  isFluid?: boolean;
};

const Modal: React.FC<ModalProps> = (props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { title, children, isOpen, onClose, isFluid } = props;
  const { buttonProps } = useButton(
    { ...props, onPress: onClose, 'aria-label': 'close' },
    ref
  );

  return (
    <ModalBase
      className={`absolute top-1/2 left-1/2 overflow-hidden border-none rounded-3xl bg-white p-8 transform -translate-x-1/2 -translate-y-1/2 ${
        isFluid ? 'container' : 'w-auto'
      }`}
      overlayClassName="bg-opacity-50 bg-black z-10 fixed top-0 left-0 right-0 bottom-0 h-full"
      isOpen={isOpen}
      onRequestClose={onClose}
      /* Avoid warnings when testing components that use react-modal */
      ariaHideApp={process.env.NODE_ENV !== 'test'}
    >
      <div className="flex justify-between items-start mb-2 gap-2">
        <div className="font-medium text-2xl">{title}</div>
        <button ref={ref} {...buttonProps}>
          <XIcon className="h-6 w-6 text-neutral" />
        </button>
      </div>

      <div>{children}</div>
    </ModalBase>
  );
};

export default Modal;
