import React, { useEffect, useState } from 'react';
import Authenticated from '../../templates/Authenticated';
import Unauthenticated from '../../templates/Unauthenticated';
import { useParams } from 'react-router-dom';
import { useEntry } from '../../contexts/entry.context';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Loading from '../../components/Loading';
import { getEntry } from '../../services/entries';
import logger from '../../lib/logger';

const AuthResolver = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading loadingText="Loading..." />;
  }

  return isAuthenticated ? <Authenticated /> : <Unauthenticated />;
};

const EntryNotFound = () => {
  return (
    <div className="p-4 sm:p-20">
      <p>The entry was not found. Please make sure to access the right URL.</p>
    </div>
  );
};

const EntryNotValid = () => {
  return (
    <div className="p-4 sm:p-20">
      <p>
        Your entry is not valid, please make sure to fill all the requirements
        before saving the entry.
      </p>
    </div>
  );
};

const Demo: React.FC = () => {
  const { entryId } = useParams();
  const { entry, setEntry } = useEntry();
  const [isLoadingEntry, setIsLoadingEntry] = useState<boolean>(true);

  const loadEntry = async () => {
    try {
      setIsLoadingEntry(true);
      const data = await getEntry(entryId as string);
      setEntry(data);
    } catch (e) {
      logger.error(
        {
          entryId: entryId,
        },
        'Entry not found when requested'
      );
    }
    setIsLoadingEntry(false);
  };

  useEffect(() => {
    loadEntry();
  }, []);

  if (isLoadingEntry) {
    return <Loading loadingText="Loading..." />;
  }

  if (!entry) {
    return <EntryNotFound />;
  }

  return entry?.variables[entry?.technologyDomainKey] &&
    entry?.variables[entry?.technologyClientIDKey] ? (
    <Auth0Provider
      domain={entry?.variables[entry?.technologyDomainKey]}
      clientId={entry?.variables[entry?.technologyClientIDKey]}
      redirectUri={window.location.href}
    >
      <div className="mx-auto container h-full">
        <AuthResolver />
      </div>
    </Auth0Provider>
  ) : (
    <EntryNotValid />
  );
};

export default Demo;
