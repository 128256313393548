import React from 'react';

const PageNotFound: React.FC = () => {
  return (
    <div className="container mx-auto flex justify-center items-center h-full">
      <div className="flex gap-2 p-4 sm:p-20">
        <span className="text-primary font-medium text-4xl border-r border-neutral-lightest pr-4">
          404
        </span>
        <div className="pl-4">
          <p className="text-4xl font-medium">Page not found</p>
          <p className="text-neutral-medium">
            Please check the URL in the address bar and try again.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
