import React, { useEffect, useState } from 'react';
import { LogoutIcon, ExclamationIcon } from '@heroicons/react/outline';
import Button from '../../components/Button';
import PrettyJSON from '../../components/PrettyJSON';
import BlurredContent from '../../components/BlurredContent';
import NewsletterCallToAction from '../../components/NewsletterCallToAction';
import { useAuth0, User } from '@auth0/auth0-react';
import { useEntry } from '../../contexts/entry.context';
import { userName, selectUserNameProperty } from '../../util/username';
import { completeEntry, getTopEntries, saveName } from '../../services/entries';
import { IEntry } from '../../models/entry';
import { formatDuration, intervalToDuration } from 'date-fns';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import ElapsedTimeTooltip from './TooltipElapsedTime';

const formatElapsedTime = (timeElapsed: number) =>
  formatDuration(intervalToDuration({ start: 0, end: timeElapsed }));

const formatElapsedTimeWithMillis = (timeElapsed: number) =>
  `${formatElapsedTime(timeElapsed)} ${timeElapsed % 1000} milliseconds`;

const filterMyEntry = (entries: Array<IEntry>, entry: IEntry) =>
  entries.filter(
    (_entry) => _entry._id !== entry._id || Boolean(entry.publicLeaderboardName)
  );

const existsMyEntry = (entries: Array<IEntry>, entry: IEntry) =>
  Boolean(entries.find((_entry) => _entry._id === entry._id));

const Authenticated: React.FC = () => {
  const { user, logout } = useAuth0();
  const { entry, setEntry } = useEntry();
  const [publicLeaderboardName, setPublicLeaderboardName] = useState<string>();
  if (!entry) return <Loading loadingText="Loading..." />;
  const [displayName] = useState(
    userName(selectUserNameProperty(user as User))
  );

  const [topEntries, setTopEntries] = useState<Array<IEntry>>([]);
  const [reloadTopEntries, setReloadTopEntries] = useState(false);
  const [isEntryInTop, setIsEntryInTop] = useState(false);
  useEffect(() => {
    if (!entry) return;
    getTopEntries(entry.eventSlug).then((entries) => {
      setTopEntries(filterMyEntry(entries, entry));
      setIsEntryInTop(existsMyEntry(entries, entry));
    });
  }, [entry.eventSlug, reloadTopEntries]);

  const handleLogout = () => logout({ returnTo: window.location.href });

  const [isModelOpen, setIsModelOpen] = useState(false);
  const closeModal = () => setIsModelOpen(false);
  const onSaveName = () => {
    setEntry({
      ...entry,
      publicLeaderboardName: publicLeaderboardName || 'Anonymous',
    });
    saveName(entry._id, publicLeaderboardName || 'Anonymous')
      .then(() => setReloadTopEntries(!reloadTopEntries))
      .then(closeModal);
  };

  useEffect(() => {
    if (!entry || !user) return;
    completeEntry(entry._id, user.email as string).then((data) => {
      setEntry(data);
    });
  }, [entry]);

  return (
    <div className="p-4 sm:p-20">
      <section className="mb-20 pb-10 border-b border-grey">
        <div className="text-4xl font-medium mb-4 text-center">
          <h1>Leaderboard</h1>
        </div>
        {topEntries.length === 0 ? (
          <p className="text-center">
            The leaderboard is still empty. Be the first!
          </p>
        ) : (
          <></>
        )}
        <Modal
          title="Public Leaderboard Name"
          isOpen={isModelOpen}
          onClose={closeModal}
        >
          <div className="mt-5 relative">
            <h2>
              {publicLeaderboardName === '' ? (
                <>
                  Since you did not provide a name,{' '}
                  <strong>&quot;Anonymous&quot;</strong> will be assigned
                  instead.
                </>
              ) : (
                <>
                  <strong>{publicLeaderboardName}</strong> will be shown
                  publicly.
                </>
              )}
              <br></br>
              If you are a winner we will contact you with the{' '}
              <strong>email</strong> you used to login.
            </h2>
          </div>
          <div className="mt-5 sm:flex sm:flex-row-reverse">
            <button
              onClick={onSaveName}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Accept
            </button>
            <button
              onClick={closeModal}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </Modal>
        <div className="shadow-2xl mt-10 overflow-x-auto relative sm:rounded-lg">
          <table className="w-full text-sm text-left dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Name
                </th>
                <th scope="col" className="py-3 px-6">
                  Time
                </th>
              </tr>
            </thead>
            <tbody>
              {entry.publicLeaderboardName ? (
                <></>
              ) : (
                <tr className="bg-card border-b border-grey">
                  <th
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div>
                      <label
                        htmlFor="price"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Enter a name to show publicly, or leave blank to set as
                        &apos;Anonymous&apos;
                      </label>
                      <div className="mt-2 w-9/12 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="price"
                          id="price"
                          onChange={(e) =>
                            setPublicLeaderboardName(e.target.value)
                          }
                          className="focus:ring-indigo-500 focus:border-indigo-500  w-full h-7 pl-2 pr-12 sm:text-sm border-gray-300 rounded-md"
                        />
                        <input
                          onClick={(e) => {
                            setIsModelOpen(true);
                          }}
                          type="button"
                          value="Set Name"
                          className="focus:ring-indigo-500 focus:border-indigo-500 h-7 ml-5 pl-2 pr-2 sm:text-sm bg-white shadow-sm border rounded-md"
                        />
                      </div>
                    </div>
                  </th>
                  <td className="py-4 px-6">
                    {entry.timeElapsed &&
                      formatElapsedTime(entry.timeElapsed as number)}
                  </td>
                </tr>
              )}
              {topEntries.map((entry) => (
                <tr
                  key={entry._id}
                  className="border-b border-grey even:bg-white odd:bg-card"
                >
                  <td
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {entry.publicLeaderboardName ?? 'Anonymous'}
                  </td>
                  <td className="py-4 px-6">
                    <ElapsedTimeTooltip
                      text={
                        (entry.timeElapsed &&
                          formatElapsedTimeWithMillis(
                            entry.timeElapsed as number
                          )) ||
                        ''
                      }
                    >
                      {entry.timeElapsed &&
                        formatElapsedTime(entry.timeElapsed as number)}
                    </ElapsedTimeTooltip>
                  </td>
                </tr>
              ))}
              {!isEntryInTop && entry.publicLeaderboardName ? (
                <>
                  <tr className="even:bg-white odd:bg-card">
                    <td colSpan={2} className="text-center font-extrabold">
                      .<br></br>.<br></br>.
                    </td>
                  </tr>
                  <tr
                    key={entry._id}
                    className="border-b border-t border-grey even:bg-white odd:bg-card"
                  >
                    <td
                      scope="row"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {entry.publicLeaderboardName ?? 'Anonymous'}
                    </td>
                    <td className="py-4 px-6">
                      {formatElapsedTime(entry.timeElapsed as number)}
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </section>
      <section className="text-center mx-auto">
        <div className="text-4xl font-mediumm mb-4">
          <h2>Congratulations,</h2>
          <h2>you got the login form working 🎉</h2>
        </div>
      </section>

      <section className="mt-20 pb-10 border-b border-grey">
        <div className="max-w-xl lg:max-w-3xl mx-auto">
          <div
            className="rounded-lg shadow-2xl flex flex-col lg:flex-row relative items-center
            bg-gradient-to-br from-neutral-lightest via-neutral-lightest to-white
          "
          >
            <div className="relative w-full lg:w-auto lg:h-full flex justify-center py-4 lg:p-12">
              <div className="absolute top-0 w-full z-10 h-[calc(50%+8px)] lg:h-full lg:w-[calc(50%)] lg:left-0 bg-gradient-to-br from-black to-neutral-darker"></div>
              <img
                className="relative rounded-full z-20 h-32 w-32 sm:h-48 sm:w-48"
                src={user?.picture}
                alt="User name goes here"
              />
            </div>

            <img
              className="absolute top-10 right-10 hidden sm:block"
              src="./img/auth0-black.png"
              alt="Auth0 black logo"
            />

            <div className="lg:p-4 p-12 text-center lg:text-left">
              <div className="text-2xl font-medium">
                <p>My name is {displayName},</p>
                <p>and I just created an</p>
                <p>@Auth0 login form in minutes!</p>
              </div>
              <p className="opacity-70 mt-6 text-sm">Entry ID: {entry._id}</p>
              <img
                className="mt-6 mx-auto lg:hidden"
                src="./img/auth0-black.png"
                alt="Auth0 black logo"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-20 pb-20 border-b border-grey">
        <div className="max-w-xl lg:max-w-6xl mx-auto flex flex-col md:flex-row">
          <img
            className="mb-8 mx-auto max-h-32 md:max-h-60"
            src={entry.prizeImageUrl}
            alt="Challenge prize image"
          />
          <div className="bg-card p-12 rounded-lg">
            <h3 className="text-xl font-medium mb-4">
              Good luck with the {entry.prizeDescription}!
            </h3>
            <div className="opacity-70">
              <p className="mb-4">
                Your time has been logged and a winner will be selected
                according to the{' '}
                <a
                  href={`${process.env.REACT_APP_ENTRY_PLATFORM_API_URL}/${entry.eventSlug}/rules`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary hover:text-primary-darkest hover:underline"
                >
                  Official Contest Rules
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-20 grid lg:grid-cols-3 grid-cols-1 gap-12 pb-20 border-b border-grey">
        <div className="col-span-1">
          <h3 className="text-2xl font-medium mb-4">Here’s your user info</h3>
          <p className="mb-8 text-sm opacity-70">
            When you logged in, this demo app received a JSON Web Token from
            Auth0 containing your identity information.
          </p>
          <Button onPress={handleLogout}>
            <div className="flex items-center">
              Log out
              <LogoutIcon className="ml-2 h-5 w-5" />
            </div>
          </Button>
        </div>

        <div className="border border-grey p-4 col-span-2">
          <div className="bg-black text-white font-medium p-6 flex items-center gap-4">
            <ExclamationIcon className="ml-2 h-5 w-5" />
            <p>This contains your personal info – don’t share this!</p>
          </div>
          <div className="mt-4">
            <BlurredContent>
              <PrettyJSON data={user || {}} />
            </BlurredContent>
          </div>
        </div>
      </section>

      <section className="lg:grid lg:grid-cols-2 lg:gap-24 mt-20">
        <NewsletterCallToAction />
      </section>
    </div>
  );
};

export default Authenticated;
