import { User } from '@auth0/auth0-react';

/**
 * If the name contains dots, it might be taken from the email address,
 * so let's convert to space separated camel case.
 *
 * @returns The user name ready to be displayed.
 *
 * @example
 
 * ```javascript
 * userName("jane.smith") // "Jane Smith"
 * ```
 * 
 * */
export const userName = (name = ''): string => {
  const nameSplit = name.split(/[.+]/);
  nameSplit.forEach((part, idx, arr) => {
    arr[idx] = part.charAt(0).toUpperCase() + part.slice(1);
  });
  return nameSplit.join(' ');
};

/**
 * If the authentication type is Auth0 (email|password) we extract the username from the `nickname` or the `name`.
 * Otherwise it will come from the `name` property as it is already formated.
 *
 * @returns A string with the user name or nickname
 * */
export const selectUserNameProperty = (
  user: Pick<User, 'nickname' | 'name' | 'sub'>
): string => {
  const isAuth0Authentication = user.sub?.startsWith('auth0');
  if (isAuth0Authentication) {
    return user.nickname || user.name || '';
  }
  return user.name || '';
};
