import React from 'react';
import { useEntry } from '../../contexts/entry.context';
import Logo from '../Logo';

const Header: React.FC = () => {
  const { entry } = useEntry();

  return (
    <header className="bg-black text-white">
      <div className="container mx-auto flex flex-col box-border p-4 sm:flex-row sm:justify-between gap-2">
        <span className="text-2xl font-medium">{entry?.eventName || ''}</span>
        <span className="flex items-center gap-1 text-sm">
          powered by <Logo className="h-6" />
        </span>
      </div>
    </header>
  );
};

export default Header;
