import axios from 'axios';
import { IEntry } from '../models/entry';

export const getEntry = async (entryId: string): Promise<IEntry> => {
  const { data } = await axios.get<IEntry>(
    `${process.env.REACT_APP_ENTRY_PLATFORM_API_URL}/api/entries/${entryId}`
  );
  return data;
};

export const completeEntry = async (
  entryId: string,
  email: string
): Promise<IEntry> => {
  const { data } = await axios.post<IEntry>(
    `${process.env.REACT_APP_ENTRY_PLATFORM_API_URL}/api/entries/complete/${entryId}`,
    { email }
  );
  return data;
};

export const saveName = async (entryId: string, name: string) => {
  const { data } = await axios.post<IEntry>(
    `${process.env.REACT_APP_ENTRY_PLATFORM_API_URL}/api/entries/name/${entryId}`,
    { publicLeaderboardName: name }
  );
  return data;
};

export const getTopEntries = async (slug: string): Promise<Array<IEntry>> => {
  const { data } = await axios.get<Array<IEntry>>(
    `${process.env.REACT_APP_ENTRY_PLATFORM_API_URL}/api/entries?limit=10&slug=${slug}&fields=publicLeaderboardName,timeElapsed`
  );

  return data;
};
