import QRCode from 'qrcode.react';
import Button from '../../components/Button';
import { LockOpenIcon } from '@heroicons/react/solid';
import NewsletterCallToAction from '../../components/NewsletterCallToAction';
import { useAuth0 } from '@auth0/auth0-react';

const Unauthenticated: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleAuthentication = async () => {
    await loginWithRedirect();
  };

  return (
    <div className="p-4 sm:p-20">
      <section className="grid lg:grid-cols-4 grid-cols-1 lg:gap-8 gap-20 items-center pb-20 border-b border-grey">
        <div className="col-span-2 bg-black text-white rounded-lg p-16">
          <img src="./img/check.png" alt="A checkmark" />
          <h2 className="text-4xl font-medium mt-4">
            You&apos;re on your way!
          </h2>
          <div className="text-grey text-lg mt-4 mb-8">
            <p className="mt-3">
              It looks like you&apos;ve configured your Domain and Client ID
              properly.
            </p>
            <p className="mt-3">
              Now click the button below and{' '}
              <strong className="text-white">
                sign up for an account in your new demo app
              </strong>
              .
            </p>
            <p className="mt-3">
              Note: whichever email address you use will be the way we&apos;ll
              contact you if you&apos;re a winner!
            </p>
          </div>
          <Button variant="secondary" onPress={handleAuthentication}>
            <div className="flex items-center">
              <LockOpenIcon className="mr-2 h-5 w-5" />
              Authenticate me
            </div>
          </Button>
        </div>

        <div className="col-span-2 flex gap-14">
          <div>
            <h3 className="text-2xl font-medium mb-2">
              Log in on another device?
            </h3>
            <p>
              If you&apos;ve completed the first part on a laptop and want to
              see your demo app on a mobile phone, scan this QR Code!
            </p>
          </div>
          <QRCode size={160} value={window.location.href} />
        </div>
      </section>

      <section className="grid lg:grid-cols-3 grid-cols-1 gap-8 mt-20 pb-20 border-b border-neutral-lightest">
        <div className="col-span-1 text-2xl font-medium">
          <p>A note on user profile data</p>
          <p>from social providers</p>
        </div>
        <p className="col-span-2 opacity-70">
          When you click login, you&apos;ll get the choice of creating an
          account with an email address and password, or using a social login
          provider. If you use a social provider, Auth0 will never know the
          password you have with them. The data returned from the social
          provider goes into the tenant you created at Auth0, and a subset of
          that is returned to the site owner in the ID Token, but never your
          password. You&apos;ll see that on this demo site after you log in.
          Your identity information will not be transmitted to the hosting
          provider (Vercel, for this demo app). For more information on Auth0’s
          data processing activities, please review Auth0&apos;s Privacy Policy.
        </p>
      </section>

      <section className="lg:grid lg:grid-cols-2 lg:gap-24 mt-20">
        <NewsletterCallToAction />
      </section>
    </div>
  );
};

export default Unauthenticated;
