import React from 'react';

const PrettyJSON: React.FC<{ data: Record<string, unknown> }> = React.memo(
  ({ data }) => (
    <div>
      <pre className="max-h-96 overflow-auto">
        {JSON.stringify(data, null, 2)}
      </pre>
    </div>
  )
);

export default PrettyJSON;
