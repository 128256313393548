import Footer from './components/Footer';
import Header from './components/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';
import Demo from './pages/Demo';
import EntryProvider from './contexts/entry.context';

function App() {
  const GotoEntryPlatform = () => {
    window.location.assign(`${process.env.REACT_APP_ENTRY_PLATFORM_API_URL}`);
    return null;
  };

  return (
    <EntryProvider>
      <div className="flex flex-col h-screen justify-between">
        <Header />
        <main className="flex-1 pb-20">
          <BrowserRouter>
            <Routes>
              <Route path="/:entryId" element={<Demo />} />
              <Route path="/" element={<GotoEntryPlatform />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </main>
        <Footer />
      </div>
    </EntryProvider>
  );
}

export default App;
