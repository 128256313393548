import React from 'react';
import Button from '../Button';
import Logo from '../Logo';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="box-border bg-black text-white">
      <div className="container flex items-center flex-col mx-auto p-4 sm:p-12 text-center">
        <Logo />

        <div className="my-8">
          <p className="text-2xl font-medium">
            This demo was brought to you by Auth0.
          </p>
          <p className="text-lg opacity-50">
            Play with Auth0 without the hassle of setting up a local development
            environment.
          </p>
        </div>

        <a href="https://auth0.com/signup" target="_blank" rel="noreferrer">
          <Button variant="primary">Create free account</Button>
        </a>
      </div>

      <div className="container flex justify-between mx-auto py-6 p-4 text-sm border-t border-white border-opacity-20">
        <span className="opacity-50">©{currentYear} Auth0</span>
        <span>
          <span>
            <a
              href="https://auth0.com/privacy"
              target="_blank"
              rel="noreferrer"
              className="opacity-100"
            >
              Privacy
            </a>
          </span>
          <span className="opacity-50"> • </span>
          <span className="opacity-50">Is there something wrong?</span>{' '}
          <a
            href="https://auth0.com/contact-us"
            target="_blank"
            rel="noreferrer"
            className="opacity-100"
          >
            Let us know
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
