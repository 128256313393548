import React, { useState } from 'react';
import Button from '../Button';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

const BlurredContent: React.FC = ({ children }) => {
  const [isBlurred, setIsBlurred] = useState<boolean>(true);

  return (
    <div className="relative">
      {isBlurred && (
        <div className="absolute h-full w-full flex justify-center items-center z-10">
          <Button onPress={() => setIsBlurred(false)}>
            <div className="flex items-center">
              <EyeIcon className="mr-2 h-5 w-5" /> Click to show hidden content
            </div>
          </Button>
        </div>
      )}
      <div
        data-testid="blur-container"
        className={`relative transition-all duration-500 ${
          isBlurred ? 'filter blur-lg' : ''
        }`}
      >
        <div className="absolute right-2 top-2">
          {!isBlurred && (
            <Button onPress={() => setIsBlurred(true)}>
              <div className="flex items-center">
                <EyeOffIcon className="mr-2 h-5 w-5" /> Hide again
              </div>
            </Button>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default BlurredContent;
