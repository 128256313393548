import pino from 'pino';
import { logflarePinoVercel } from 'pino-logflare';

const { stream, send } = logflarePinoVercel({
  apiKey: process.env.REACT_APP_LOG_FLARE_API_KEY || '',
  sourceToken: process.env.REACT_APP_LOG_FLARE_SOURCE_ID || '',
});

const logger = pino(
  {
    browser: {
      transmit: {
        level: 'info',
        send: send,
      },
    },
    level: 'debug',
    base: {
      env: process.env.NODE_ENV,
    },
  },
  stream
);

export default logger;
