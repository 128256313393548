import React, { createContext, useContext, useState } from 'react';
import { IEntry } from '../models/entry';

type EntryContextType = {
  entry: IEntry | null;
  setEntry: (entry: IEntry) => void;
};

const EntryContext = createContext<EntryContextType>({
  entry: null,
  setEntry: () => {
    throw 'No entry provider';
  },
});

export default function EntryProvider(props: {
  children: React.ReactChild;
}): JSX.Element {
  const [entry, setEntry] = useState<IEntry | null>(null);

  return (
    <EntryContext.Provider value={{ entry, setEntry }}>
      {props.children}
    </EntryContext.Provider>
  );
}

export function useEntry(): EntryContextType {
  const context = useContext(EntryContext);
  const { entry, setEntry } = context;
  return { entry, setEntry };
}
