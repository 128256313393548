import React from 'react';
import Spinner from '../Spinner';

const Loading: React.FC<{ loadingText: string }> = ({ loadingText }) => {
  return (
    <div className="h-full">
      <div className="w-full h-full flex justify-center items-center">
        <span className="opacity-75 mx-auto block">
          <Spinner />
          <p className="mt-4">{loadingText}</p>
        </span>
      </div>
    </div>
  );
};

export default Loading;
