import React, { useRef } from 'react';
import type { AriaButtonProps } from '@react-types/button';
import { useButton } from '@react-aria/button';

type ButtonVariant = 'primary' | 'dark' | 'secondary' | 'default';
type ButtonProps = AriaButtonProps & {
  variant?: ButtonVariant;
  loading?: boolean;
};

const ButtonVariantClasses: Record<ButtonVariant, string> = {
  primary:
    'bg-primary text-white border border-primary hover:bg-primary-darkest',
  dark: 'bg-neutral-darkest text-white border border-neutral-darkest hover:bg-neutral-darker',
  secondary:
    'bg-secondary text-white border border-secondary hover:bg-secondary-darkest',
  default:
    'bg-white text-neutral-darkest border border-grey hover:bg-neutral-lightest',
};

export const Button: React.FC<ButtonProps> = (props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      ...props,
      isDisabled: props.loading,
    },
    ref
  );
  const { children } = props;

  return (
    <button
      ref={ref}
      {...buttonProps}
      className={`block font-medium text-sm leading-6 py-1 px-4 rounded-sm ${
        ButtonVariantClasses[props.variant || 'default']
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
