import { ArrowRightIcon } from '@heroicons/react/outline';
import React, { Fragment, useState } from 'react';
import Button from '../Button';
import Spinner from '../Spinner';
import { validateEmail } from '../../util/email';
import { registerNewEmail } from '../../services/emails';

const NewsletterCallToAction: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>();
  const [email, setEmail] = useState<string>('');

  const verifyEmail = (): boolean => {
    const isValid = validateEmail(email);
    setErrorMessage(!isValid ? 'Please enter a valid email' : '');
    return isValid;
  };

  const handleError = (
    errorName: 'unexpected' | 'EmailNotValid' | 'AlreadyExists'
  ) => {
    switch (errorName) {
      case 'AlreadyExists':
        setErrorMessage('The given email already exists');
        break;
      case 'EmailNotValid':
        setErrorMessage('Please enter a valid email');
        break;
      case 'unexpected':
      default:
        setErrorMessage('Oops, we had a problem, please try again later');
        break;
    }
  };

  const handleSuccess = () => {
    setEmail('');
    setErrorMessage('');
    setShowSuccessMessage(true);

    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 1_000);
  };

  const handleSubscribe = async () => {
    const isEmailValid = verifyEmail();
    if (!isEmailValid) {
      return;
    }

    try {
      setIsLoading(true);
      await registerNewEmail(email);
      handleSuccess();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      handleError(e?.errorName || 'unexpected');
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      <div>
        <h2 className="text-2xl font-semibold">
          While you&apos;re here, did you know the Auth0 Developer Relations
          Team sends out a curated monthly Newsletter?
        </h2>
        <div className="relative my-8">
          <input
            type="text"
            className="h-14 w-full px-4 z-0 focus:shadow focus:outline-none border border-neutral-lightest rounded-lg overflow-hidden"
            placeholder="Your email"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <div className="absolute top-2.5 right-2">
            <Button
              variant="dark"
              type="button"
              onPress={handleSubscribe}
              loading={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center">
                  Loading...
                  <Spinner classes="ml-2 h-5 w-5 text-white" />
                </div>
              ) : (
                <div className="flex items-center">
                  Subscribe
                  <ArrowRightIcon className="ml-2 h-5 w-5" />
                </div>
              )}
            </Button>
          </div>
          <p className="mt-2 text-sm text-red">{errorMessage || ' '}</p>
          {showSuccessMessage && (
            <p className="mt-2 text-sm text-primary">Thanks for subscribing!</p>
          )}
        </div>

        <p className="mt-10">
          Subscribe today, and you too can get the latest news from the
          Developer Relations team, including the monthly terminal tip!
        </p>

        {/* <p className="mt-10">
          Subscribe today,{' '}
          <a
            href={`#`}
            target="_blank"
            className="text-primary hover:text-primary-darkest hover:underline"
            rel="noreferrer"
          >
            or take a peek at previous editions
          </a>{' '}
          to get an idea of the type of content you&apos;ll receive.
        </p> */}
      </div>

      <div className="mt-6 lg:mt-0 overflow-hidden h-48 sm:h-80 relative flex items-center">
        <img
          src="./img/zero-index.png"
          alt="Zero index"
          placeholder="blur"
          className="z-10"
        />
        <div className="absolute top-0 right-0 bg-gradient-to-r from-cornflower to-primary h-full w-2/3 rounded-3xl "></div>
      </div>
    </Fragment>
  );
};

export default NewsletterCallToAction;
