import axios from 'axios';
import logger from '../lib/logger';
import { IEmail } from '../models/email';

export const registerNewEmail = async (
  email: string
): Promise<IEmail | undefined> => {
  try {
    const { data } = await axios.post<IEmail>(
      `${process.env.REACT_APP_ENTRY_PLATFORM_API_URL}/api/emails`,
      {
        email,
      }
    );
    return data;
  } catch (e: unknown) {
    logger.error(
      {
        error: e,
      },
      'Error when calling the registerEmail API'
    );
    if (axios.isAxiosError(e)) {
      const errorName = e.response?.data.name || 'unexpected';
      throw { errorName };
    }
    throw { errorName: 'unexpected' };
  }
};
